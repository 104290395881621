import axios from "axios";
const host = process.env.VUE_APP_API_SERVER;

const instance = axios.create({
  baseURL: host,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
