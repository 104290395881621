/* eslint-disable no-async-promise-executor */
import Vue from 'vue'
import instance from './axiosInstance'
import serviceList from './serviceList'
import { TYPE } from 'vue-toastification'

const services = async (type, url, params, urlParam, silent = false) => {
  return new Promise(async (resolve, reject) => {
    const auxUrl = urlParam ? `${serviceList[url]}/${urlParam}` : serviceList[url]
    try {
      let response = null
      if (type === 'get') {
        response = await instance.get(auxUrl, params) // Usar await para capturar errores
      }
      if (type === 'delete') {
        response = await instance.delete(auxUrl, params)
      }
      if (type === 'put') {
        response = await instance.put(auxUrl, params)
      }
      if (type === 'post') {
        response = await instance.post(auxUrl, params)
      }
      resolve(response?.data)
    } catch (error) {
      reject(error)
      if (error.response?.status === 401) {
        Vue.$toast('La sesión es invalida', { type: TYPE.ERROR })
      }
      if (silent) {
        return
      }
      if (error.response?.status === 404) {
        Vue.$toast('No disponible por el momento', { type: TYPE.ERROR })
        return
      }
      Vue.$toast(error?.response?.data?.message || 'Error general', { type: TYPE.ERROR })
    }
  })
}

export default services
