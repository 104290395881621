<template>
  <router-view />
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import Vuetify from 'vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'

import Toast, { TYPE } from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

Vue.use(Toast, { timeout: 5000 })
Vue.use(Vuetify)
Vue.use(VueAxios, axios)
Vue.use(VueSession)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places,geometry,drawing,visualization',
    channel: 'shaker_suite'
  }
})

export default {
  name: 'App'
}
</script>
