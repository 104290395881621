import Vue from 'vue'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { axiosInstance } from '../utils'

const host = process.env.VUE_APP_API_SERVER
const path = '/api/check'

export default {
  check() {
    const jwt = Vue.prototype.$session.get('jwt')

    if (!jwt) {
      Vue.prototype.$session.destroy()
      return Promise.resolve(false)
    }

    const authHeader = { Authorization: `Bearer ${jwt}` }
    axios.defaults.headers.common = authHeader
    axiosInstance.defaults.headers.common = authHeader

    return axios
      .get(`${host}${path}`)
      .then((response) => {
        const isAdmin = response.data.roles.some((role) => role.toLowerCase() === 'administrador')

        if (isAdmin) {
          const decodedData = jwt_decode(jwt)
          Vue.prototype.$session.set('user', decodedData.user.username)
          Vue.prototype.$session.set('companies', response.data.empresas)
          return true
        }

        return false
      })
      .catch(() => {
        Vue.prototype.$session.destroy()
        return false
      })
  }
}
